export const API_URL = "https://saas.app.antonic.ai/app/";
export const CODEBASE_URL = "https://saas.app.antonic.ai/app/webchat/app/";
export const upperCaseAlp = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
export const dummyPhoneNumber = ["+917428723247", "+4915207955279", "+12127290149", "+447399819350", "+79057586108"];
export const dummyFirstNames = ["Adam", "Adrian", "Alan", "Andrew", "William"];
export const dummyLastNames = ["Bailey", "Ball", "Brown", "Ferguson", "James"];
export const dummyProfileImage = ["https://allworldpm.com/wp-content/uploads/2016/10/230x230-avatar-dummy-profile-pic.jpg", "https://image.shutterstock.com/image-vector/vector-design-avatar-dummy-sign-260nw-1290556063.jpg", "https://www.heuschenschrouff.com/wp-content/uploads/2017/11/team-dummy-woman.png", "https://www.heuschenschrouff.com/wp-content/uploads/2017/11/team-dummy-woman.png"];
export const userGender = ["male", "female"];
export const whatsappChannels = ["whatsapp", "twilioSms", "twilio", "dialog360"];
export const ErrorsCodes = {
  en: {
    email: "Please enter valid email!",
    number: "Please enter valid number!",
    contactNumber: "Please enter valid mobile number!",
    attachment: "only audio , video , image and documents allowed!",
    bookingTypeNotAvailable: "Booking not available",
    pleaseSelectOneItem: "Please Select at least one item!",
    back: "Back",
    skip: "Skip",
    goToPreviousStep: "Go to previous step",
    goToNextStep: "Go to next step",
    yourProgress: "Your Progress",
    typeMessage: "Type a message here",
    readMore: " ... Read More",
    readLess: " Read Less",
    fiIsSetWithValue: "FI is set with value",
    fiValueAreClear: "FI value are clear",
    progressBarIsSetTo: "Progress bar is set to",
    apIsBeenCalled: "API is been called",
    status: "Status",
    tagHasBeenAdd: "Tag has been add",
    tagHasBeenRemove: "Tag has been remove",
    flowStarted: "Flow Started",
    handoffWillBeAssignedTo: "Handoff will be assigned to",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "emotion is been recognize on text ",
    isChacked: "is checked",
    triggerMailRequestGeneratedFor: "Trigger request generated for",
    delayOf: "Delay of",
    dynamicBlockRun: "dynamicBlock is Run and status is ",
    operation: "operation Status",
    fail: "Fail",
    success: "success",
    clickHere: "Click Here",
    similarIntentName: "Similar Intent Name",
    ofNLP: "of NLP",
    withScore: "with score"
  },
  "en-AU": {
    email: "Please enter valid email!",
    number: "Please enter valid number!",
    contactNumber: "Please enter valid mobile number!",
    attachment: "only audio , video , image and documents allowed!",
    bookingTypeNotAvailable: "Booking not available",
    pleaseSelectOneItem: "Please Select at least one item!",
    back: "Back",
    skip: "Skip",
    goToPreviousStep: "Go to previous step",
    goToNextStep: "Go to next step",
    yourProgress: "Your Progress",
    typeMessage: "Type a message here",
    readMore: " ... Read More",
    readLess: " Read Less",
    fiIsSetWithValue: "FI is set with value",
    fiValueAreClear: "FI value are clear",
    progressBarIsSetTo: "Progress bar is set to",
    apIsBeenCalled: "API is been called",
    status: "Status",
    tagHasBeenAdd: "Tag has been add",
    tagHasBeenRemove: "Tag has been remove",
    flowStarted: "Flow Started",
    handoffWillBeAssignedTo: "Handoff will be assigned to",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "emotion is been recognize",
    isChacked: "is checked",
    triggerMailRequestGeneratedFor: "Trigger Mail request generated for",
    delayOf: "Delay of",
    dynamicBlockRun: "dynamicBlock is Run and status is ",
    operation: "operation Status",
    fail: "Fail",
    success: "success",
    clickHere: "Click Here",
    similarIntentName: "Similar Intent Name",
    ofNLP: "of NLP",
    withScore: "with score"
  },
  "en-CA": {
    email: "Please enter valid email!",
    number: "Please enter valid number!",
    contactNumber: "Please enter valid mobile number!",
    attachment: "only audio , video , image and documents allowed!",
    bookingTypeNotAvailable: "Booking not available",
    pleaseSelectOneItem: "Please Select at least one item!",
    back: "Back",
    skip: "Skip",
    goToPreviousStep: "Go to previous step",
    goToNextStep: "Go to next step",
    yourProgress: "Your Progress",
    typeMessage: "Type a message here",
    readMore: " ... Read More",
    readLess: " Read Less",
    fiIsSetWithValue: "FI is set with value",
    fiValueAreClear: "FI value are clear",
    progressBarIsSetTo: "Progress bar is set to",
    apIsBeenCalled: "API is been called",
    status: "Status",
    tagHasBeenAdd: "Tag has been add",
    tagHasBeenRemove: "Tag has been remove",
    flowStarted: "Flow Started",
    handoffWillBeAssignedTo: "Handoff will be assigned to",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "emotion is been recognize",
    isChacked: "is checked",
    triggerMailRequestGeneratedFor: "Trigger Mail request generated for",
    delayOf: "Delay of",
    dynamicBlockRun: "dynamicBlock is Run and status is ",
    operation: "operation Status",
    fail: "Fail",
    success: "success",
    clickHere: "Click Here",
    similarIntentName: "Similar Intent Name",
    ofNLP: "of NLP",
    withScore: "with score"
  },
  "en-GB": {
    email: "Please enter valid email!",
    number: "Please enter valid number!",
    contactNumber: "Please enter valid mobile number!",
    attachment: "only audio , video , image and documents allowed!",
    bookingTypeNotAvailable: "Booking not available",
    pleaseSelectOneItem: "Please Select at least one item!",
    back: "Back",
    skip: "Skip",
    goToPreviousStep: "Go to previous step",
    goToNextStep: "Go to next step",
    yourProgress: "Your Progress",
    typeMessage: "Type a message here",
    readMore: " ... Read More",
    readLess: " Read Less",
    fiIsSetWithValue: "FI is set with value",
    fiValueAreClear: "FI value are clear",
    progressBarIsSetTo: "Progress bar is set to",
    apIsBeenCalled: "API is been called",
    status: "Status",
    tagHasBeenAdd: "Tag has been add",
    tagHasBeenRemove: "Tag has been remove",
    flowStarted: "Flow Started",
    handoffWillBeAssignedTo: "Handoff will be assigned to",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "emotion is been recognize",
    isChacked: "is checked",
    triggerMailRequestGeneratedFor: "Trigger Mail request generated for",
    delayOf: "Delay of",
    dynamicBlockRun: "dynamicBlock is Run and status is ",
    operation: "operation Status",
    fail: "Fail",
    success: "success",
    clickHere: "Click Here",
    similarIntentName: "Similar Intent Name",
    ofNLP: "of NLP",
    withScore: "with score"
  },
  "en-IN": {
    email: "Please enter valid email!",
    number: "Please enter valid number!",
    contactNumber: "Please enter valid mobile number!",
    attachment: "only audio , video , image and documents allowed!",
    bookingTypeNotAvailable: "Booking not available",
    pleaseSelectOneItem: "Please Select at least one item!",
    back: "Back",
    skip: "Skip",
    goToPreviousStep: "Go to previous step",
    goToNextStep: "Go to next step",
    yourProgress: "Your Progress",
    typeMessage: "Type a message here",
    readMore: " ... Read More",
    readLess: " Read Less",
    fiIsSetWithValue: "FI is set with value",
    fiValueAreClear: "FI value are clear",
    progressBarIsSetTo: "Progress bar is set to",
    apIsBeenCalled: "API is been called",
    status: "Status",
    tagHasBeenAdd: "Tag has been add",
    tagHasBeenRemove: "Tag has been remove",
    flowStarted: "Flow Started",
    handoffWillBeAssignedTo: "Handoff will be assigned to",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "emotion is been recognize",
    isChacked: "is checked",
    triggerMailRequestGeneratedFor: "Trigger Mail request generated for",
    delayOf: "Delay of",
    dynamicBlockRun: "dynamicBlock is Run and status is ",
    operation: "operation Status",
    fail: "Fail",
    success: "success",
    clickHere: "Click Here",
    similarIntentName: "Similar Intent Name",
    ofNLP: "of NLP",
    withScore: "with score"
  },
  "en-US": {
    email: "Please enter valid email!",
    number: "Please enter valid number!",
    contactNumber: "Please enter valid mobile number!",
    attachment: "only audio , video , image and documents allowed!",
    bookingTypeNotAvailable: "Booking not available",
    pleaseSelectOneItem: "Please Select at least one item!",
    back: "Back",
    skip: "Skip",
    goToPreviousStep: "Go to previous step",
    goToNextStep: "Go to next step",
    yourProgress: "Your Progress",
    typeMessage: "Type a message here",
    readMore: " ... Read More",
    readLess: " Read Less",
    fiIsSetWithValue: "FI is set with value",
    fiValueAreClear: "FI value are clear",
    progressBarIsSetTo: "Progress bar is set to",
    apIsBeenCalled: "API is been called",
    status: "Status",
    tagHasBeenAdd: "Tag has been add",
    tagHasBeenRemove: "Tag has been remove",
    flowStarted: "Flow Started",
    handoffWillBeAssignedTo: "Handoff will be assigned to",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "emotion is been recognize",
    isChacked: "is checked",
    triggerMailRequestGeneratedFor: "Trigger Mail request generated for",
    delayOf: "Delay of",
    dynamicBlockRun: "dynamicBlock is Run and status is ",
    operation: "operation Status",
    fail: "Fail",
    success: "success",
    clickHere: "Click Here",
    similarIntentName: "Similar Intent Name",
    ofNLP: "of NLP",
    withScore: "with score"
  },
  nl: {
    back: "Terug",
    skip: "Overslaan",
    goToPreviousStep: "Ga naar vorige stap",
    goToNextStep: "Ga naar volgende stap",
    yourProgress: "Je voortgang",
    typeMessage: "Typ hier een bericht",
    readMore: " ... Lees meer",
    readLess: " Lees minder",
    similarIntentName: "Similar Intent Name",
    ofNLP: "of NLP",
    withScore: "with score",
    email: "Voer een geldig emailadres in!",
    number: "Voer een geldig nummer in! ",
    contactNumber: "Voer een geldig mobiel nummer in! ",
    attachment: "alleen audio, video, afbeeldingen en documenten toegestaan!",
    bookingTypeNotAvailable: "Boeking niet beschikbaar",
    pleaseSelectOneItem: "Selecteer ten minste één item!",
    fiIsSetWithValue: "FI is set with value",
    fiValueAreClear: "FI value are clear",
    progressBarIsSetTo: "Progress bar is set to",
    apIsBeenCalled: "API is been called",
    status: "Status",
    tagHasBeenAdd: "Tag has been add",
    tagHasBeenRemove: "Tag has been remove",
    flowStarted: "Flow Started",
    handoffWillBeAssignedTo: "Handoff will be assigned to",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "emotion is been recognize on text ",
    isChacked: "is checked",
    triggerMailRequestGeneratedFor: "Trigger request generated for",
    delayOf: "Delay of",
    dynamicBlockRun: "dynamicBlock is Run and status is ",
    operation: "operation Status",
    fail: "Fail",
    success: "success",
    clickHere: "Click Here"
  },
  ru: {
    email: "Пожалуйста, введите действующий адрес электронной почты! ",
    number: "Пожалуйста, введите правильный номер! ",
    contactNumber: "Пожалуйста, введите действующий номер мобильного телефона! ",
    attachment: "разрешены только аудио, видео, изображения и документы!",
    bookingTypeNotAvailable: "Бронирование недоступно",
    pleaseSelectOneItem: "Пожалуйста, выберите хотя бы один товар!",
    back: "назад",
    skip: "Пропустить",
    goToPreviousStep: "Перейти к предыдущему шагу",
    goToNextStep: "Перейти к следующему шагу",
    yourProgress: "Ваш прогресс",
    typeMessage: "Напишите здесь сообщение",
    readMore: " ... Прочитайте больше",
    readLess: "Читать меньше",
    fiIsSetWithValue: "FI установлен со значением",
    fiValueAreClear: "Значение FI очищено",
    progressBarIsSetTo: "Индикатор выполнения установлен на",
    apIsBeenCalled: "API вызван",
    status: "Статус",
    tagHasBeenAdd: "Тег добавлен",
    tagHasBeenRemove: "Тег был удален",
    flowStarted: "Поток запущен",
    handoffWillBeAssignedTo: "Передача будет назначена",
    randomizeRunAndOptions: "рандомизировать запуск и параметры",
    emotionIsBeenRecognize: "эмоция распознана",
    isChacked: "проверена",
    triggerMailRequestGeneratedFor: "инициировать почтовый запрос, созданный для",
    delayOf: "задержка",
    dynamicBlockRun: "dynamicBlock - Выполнено, а статус -",
    operation: "Статус операции",
    fail: "Ошибка",
    success: "успех",
    clickHere: "Щелкните здесь",
    similarIntentName: "Название похожего намерения",
    ofNLP: "НЛП",
    withScore: "со счетом"
  },
  "zh-HK": {
    email: "请输入有效的电子邮件！",
    number: "请输入有效号码！",
    contactNumber: "请输入有效的手机号码！",
    attachment: "只允许音频，视频，图像和文件！",
    bookingTypeNotAvailable: "無法預訂",
    pleaseSelectOneItem: "請至少選擇一項！",
    back: "后退",
    skip: "跳过",
    goToPreviousStep: "转到上一步",
    goToNextStep: "进入下一步",
    yourProgress: "你的进步",
    typeMessage: "在此处输入消息",
    readMore: "... 阅读更多",
    readLess: "少读",
    fiIsSetWithValue: "FI 设置有值",
    fiValueAreClear: "FI 值清晰",
    progressBarIsSetTo: "进度条设置为",
    apIsBeenCalled: "API 被调用",
    status: "状态",
    tagHasBeenAdd: "标签已添加",
    tagHasBeenRemove: "标签已被移除",
    flowStarted: "流程開始",
    handoffWillBeAssignedTo: "切換將分配給",
    randomizeRunAndOptions: "隨機運行和選項",
    emotionIsBeenRecognize: "情緒被識別",
    isChacked: "已檢查",
    triggerMailRequestGeneratedFor: "觸發郵件請求生成",
    delayOf: "延遲",
    dynamicBlockRun: "dynamicBlock 是运行，状态是",
    operation: "运行状态",
    fail: "失败",
    success: "成功",
    clickHere: "点击这里",
    similarIntentName: "相似意图名称",
    ofNLP: "自然语言处理",
    withScore: "有分数"
  },
  "zh-CN": {
    email: "请输入有效的电子邮件！",
    number: "请输入有效号码！",
    contactNumber: "请输入有效的手机号码！",
    attachment: "只允许音频，视频，图像和文件！",
    bookingTypeNotAvailable: "無法預訂",
    pleaseSelectOneItem: "請至少選擇一項！",
    back: "后退",
    skip: "跳过",
    goToPreviousStep: "转到上一步",
    goToNextStep: "进入下一步",
    yourProgress: "你的进步",
    typeMessage: "在此处输入消息",
    readMore: "... 閱讀更多",
    readLess: "少讀",
    fiIsSetWithValue: "FI 设置有值",
    fiValueAreClear: "FI 值清晰",
    progressBarIsSetTo: "进度条设置为",
    apIsBeenCalled: "API 被调用",
    status: "状态",
    tagHasBeenAdd: "标签已添加",
    tagHasBeenRemove: "标签已被移除",
    flowStarted: "流程开始",
    handoffWillBeAssignedTo: "切换将分配给",
    randomizeRunAndOptions: "随机运行和选项",
    emotionIsBeenRecognize: "情绪被识别",
    isChacked: "已检查",
    triggerMailRequestGeneratedFor: "触发邮件请求生成",
    delayOf: "延迟",
    dynamicBlockRun: "dynamicBlock 是运行，状态是",
    operation: "运行状态",
    fail: "失败",
    success: "成功",
    clickHere: "点击这里",
    similarIntentName: "相似意图名称",
    ofNLP: "自然语言处理",
    withScore: "有分数"
  },
  "zh-TW": {
    email: "请输入有效的电子邮件！",
    number: "请输入有效号码！",
    contactNumber: "请输入有效的手机号码！",
    attachment: "只允许音频，视频，图像和文件！",
    bookingTypeNotAvailable: "無法預訂",
    pleaseSelectOneItem: "請至少選擇一項！",
    back: "后退",
    skip: "跳过",
    goToPreviousStep: "转到上一步",
    goToNextStep: "进入下一步",
    yourProgress: "你的进步",
    typeMessage: "在此处输入消息",
    readMore: "... 閱讀更多",
    readLess: "少讀",
    fiIsSetWithValue: "FI 設置有值",
    fiValueAreClear: "FI 值清晰",
    progressBarIsSetTo: "進度條設置為",
    apIsBeenCalled: "API 被調用",
    status: "狀態",
    tagHasBeenAdd: "標籤已添加",
    tagHasBeenRemove: "標籤已被移除",
    flowStarted: "流程開始",
    handoffWillBeAssignedTo: "切換將分配給",
    randomizeRunAndOptions: "隨機運行和選項",
    emotionIsBeenRecognize: "情緒被識別",
    isChacked: "已檢查",
    triggerMailRequestGeneratedFor: "觸發郵件請求生成",
    delayOf: "延遲",
    dynamicBlockRun: "dynamicBlock 是运行，状态是",
    operation: "运行状态",
    fail: "失败",
    success: "成功",
    clickHere: "点击这里",
    similarIntentName: "相似意图名称",
    ofNLP: "自然语言处理",
    withScore: "有分数"
  },
  da: {
    email: "Indtast venligst gyldig e-mail!",
    number: "Indtast venligst gyldigt nummer!",
    contactNumber: "Indtast venligst gyldigt mobilnummer!",
    attachment: "kun lyd, video, billede og dokumenter er tilladt!",
    bookingTypeNotAvailable: "Booking er ikke tilgængelig",
    pleaseSelectOneItem: "Vælg mindst en vare!",
    back: "Tilbage",
    skip: "Springe",
    goToPreviousStep: "Gå til forrige trin",
    goToNextStep: "Gå til næste trin",
    yourProgress: "Dit fremskridt",
    typeMessage: "Skriv en besked her",
    readMore: "... Læs mere",
    readLess: "Læs mindre",
    fiIsSetWithValue: "FI er sat med værdi",
    fiValueAreClear: "FI-værdien er klar",
    progressBarIsSetTo: "Progresslinje er indstillet til",
    apIsBeenCalled: "API er blevet kaldt",
    status: "status",
    tagHasBeenAdd: "Tag er blevet tilføjet",
    tagHasBeenRemove: "Tag er blevet fjernet",
    flowStarted: "Flow påbegyndt",
    handoffWillBeAssignedTo: "Handoff vil blive tildelt til",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "følelser er blevet genkendt",
    isChacked: "er markeret",
    triggerMailRequestGeneratedFor: "Trigger Mail-anmodning genereret for",
    delayOf: "Forsinkelse af",
    dynamicBlockRun: "dynamicBlock er Kør og status er ",
    operation: "driftsstatus",
    fail: "Svigte",
    success: "succes",
    clickHere: "Klik her",
    similarIntentName: "Lignende hensigtsnavn",
    ofNLP: "af NLP",
    withScore: "med score"
  },
  fr: {
    email: "Veuillez saisir une adresse e-mail valide!",
    number: "Veuillez entrer un numéro valide!",
    contactNumber: "Veuillez saisir un numéro de portable valide! ",
    attachment: "seuls l'audio, la vidéo, l'image et les documents sont autorisés!",
    bookingTypeNotAvailable: "Réservation non disponible",
    pleaseSelectOneItem: "Veuillez sélectionner au moins un article !",
    back: "Dos",
    skip: "Sauter",
    goToPreviousStep: "Aller à l'étape précédente",
    goToNextStep: "Passer à l'étape suivante",
    yourProgress: "Votre progression",
    typeMessage: "Tapez un message ici",
    readMore: "...Lire la suite",
    readLess: "lireMoins",
    fiIsSetWithValue: "FI est défini avec une valeur",
    fiValueAreClear: "Les valeurs FI sont claires",
    progressBarIsSetTo: "La barre de progression est définie sur",
    apIsBeenCalled: "L'API a été appelée",
    status: "Statut",
    tagHasBeenAdd: "La balise a été ajoutée",
    tagHasBeenRemove: "La balise a été supprimée",
    flowStarted: "Flux démarré",
    handoffWillBeAssignedTo: "Le transfert sera attribué à",
    randomizeRunAndOptions: "Randomiser l'exécution et les options",
    emotionIsBeenRecognize: "l'émotion a été reconnue",
    isChacked: "est cochée",
    triggerMailRequestGeneratedFor: "Déclencher la demande de courrier généré pour",
    delayOf: "Délai de",
    dynamicBlockRun: "dynamicBlock est Exécuté et le statut est",
    operation: "état de l'opération",
    fail: "Échouer",
    success: "Succès",
    clickHere: "Cliquez ici",
    similarIntentName: "Nom d'intention similaire",
    ofNLP: "de la PNL",
    withScore: "avec note"
  },
  "fr-CA": {
    email: "Veuillez saisir une adresse e-mail valide!",
    number: "Veuillez entrer un numéro valide!",
    contactNumber: "Veuillez saisir un numéro de portable valide! ",
    attachment: "seuls l'audio, la vidéo, l'image et les documents sont autorisés!",
    bookingTypeNotAvailable: "Réservation non disponible",
    pleaseSelectOneItem: "Veuillez sélectionner au moins un article !",
    back: "Dos",
    skip: "Sauter",
    goToPreviousStep: "Aller à l'étape précédente",
    goToNextStep: "Passer à l'étape suivante",
    yourProgress: "Votre progression",
    typeMessage: "Tapez un message ici",
    readMore: "...Lire la suite",
    readLess: "lireMoins",
    fiIsSetWithValue: "FI est défini avec une valeur",
    fiValueAreClear: "Les valeurs FI sont claires",
    progressBarIsSetTo: "La barre de progression est définie sur",
    apIsBeenCalled: "L'API a été appelée",
    status: "Statut",
    tagHasBeenAdd: "La balise a été ajoutée",
    tagHasBeenRemove: "La balise a été supprimée",
    flowStarted: "Flux démarré",
    handoffWillBeAssignedTo: "Le transfert sera attribué à",
    randomizeRunAndOptions: "Randomiser l'exécution et les options",
    emotionIsBeenRecognize: "l'émotion a été reconnue",
    isChacked: "est cochée",
    triggerMailRequestGeneratedFor: "Déclencher la demande de courrier généré pour",
    delayOf: "Délai de",
    dynamicBlockRun: "dynamicBlock est Exécuté et le statut est",
    operation: "état de l'opération",
    fail: "Échouer",
    success: "Succès",
    clickHere: "Cliquez ici",
    similarIntentName: "Nom d'intention similaire",
    ofNLP: "de la PNL",
    withScore: "avec note"
  },
  "fr-FR": {
    email: "Veuillez saisir une adresse e-mail valide!",
    number: "Veuillez entrer un numéro valide!",
    contactNumber: "Veuillez saisir un numéro de portable valide! ",
    attachment: "seuls l'audio, la vidéo, l'image et les documents sont autorisés!",
    bookingTypeNotAvailable: "Réservation non disponible",
    pleaseSelectOneItem: "Veuillez sélectionner au moins un article !",
    back: "Dos",
    skip: "Sauter",
    goToPreviousStep: "Aller à l'étape précédente",
    goToNextStep: "Passer à l'étape suivante",
    yourProgress: "Votre progression",
    typeMessage: "Tapez un message ici",
    readMore: "...Lire la suite",
    readLess: "lireMoins",
    fiIsSetWithValue: "FI est défini avec une valeur",
    fiValueAreClear: "Les valeurs FI sont claires",
    progressBarIsSetTo: "La barre de progression est définie sur",
    apIsBeenCalled: "L'API a été appelée",
    status: "Statut",
    tagHasBeenAdd: "La balise a été ajoutée",
    tagHasBeenRemove: "La balise a été supprimée",
    flowStarted: "Flux démarré",
    handoffWillBeAssignedTo: "Le transfert sera attribué à",
    randomizeRunAndOptions: "Randomiser l'exécution et les options",
    emotionIsBeenRecognize: "l'émotion a été reconnue",
    isChacked: "est cochée",
    triggerMailRequestGeneratedFor: "Déclencher la demande de courrier généré pour",
    delayOf: "Délai de",
    dynamicBlockRun: "dynamicBlock est Exécuté et le statut est",
    operation: "état de l'opération",
    fail: "Échouer",
    success: "Succès",
    clickHere: "Cliquez ici",
    similarIntentName: "Nom d'intention similaire",
    ofNLP: "de la PNL",
    withScore: "avec note"
  },
  de: {
    email: "Bitte eine gültige E-Mail-Adresse eintragen!",
    number: "Bitte geben Sie eine gültige Nummer ein!",
    contactNumber: "Bitte geben Sie eine gültige Handynummer ein!",
    attachment: "Nur Audio, Video, Bild und Dokumente erlaubt!",
    bookingTypeNotAvailable: "Buchung nicht möglich",
    pleaseSelectOneItem: "Bitte wählen Sie mindestens einen Artikel aus!",
    back: "Zurück",
    skip: "Überspringen",
    goToPreviousStep: "Gehe zum vorherigen Schritt",
    goToNextStep: "Gehe zum nächsten Schritt",
    yourProgress: "Dein Fortschritt",
    typeMessage: "Geben Sie hier eine Nachricht ein",
    readMore: "...Weiterlesen",
    readLess: "Lese weniger",
    fiIsSetWithValue: "FI wird mit Wert gesetzt",
    fiValueAreClear: "FI-Werte sind klar",
    progressBarIsSetTo: "Fortschrittsbalken ist gesetzt auf",
    apIsBeenCalled: "API wurde aufgerufen",
    Status: "Status",
    tagHasBeenAdd: "Tag wurde hinzugefügt",
    tagHasBeenRemove: "Tag wurde entfernt",
    flowStarted: "Flow påbegyndt",
    handoffWillBeAssignedTo: "Handoff vil blive tildelt til",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "følelser er blevet genkendt",
    isChacked: "er markeret",
    triggerMailRequestGeneratedFor: "Trigger Mail-anmodning genereret for",
    delayOf: "Forsinkelse af",
    dynamicBlockRun: "dynamicBlock ist Run und Status ist",
    operation: "Betriebsstatus",
    fail: "Fail",
    success: "Scheitern",
    clickHere: "Click Here",
    similarIntentName: "Klicken Sie hier",
    ofNLP: "des NLP",
    withScore: "mit Punktzahl"
  },
  hi: {
    email: "कृपया वैध ईमेल दर्ज़ करें!",
    number: "कृपया मान्य नंबर दर्ज करें!",
    contactNumber: "कृपया मान्य मोबाइल नंबर दर्ज करें!",
    attachment: "केवल ऑडियो, वीडियो, छवि और दस्तावेजों की अनुमति है!",
    bookingTypeNotAvailable: "बुकिंग उपलब्ध नहीं",
    pleaseSelectOneItem: "कृपया कम से कम एक आइटम चुनें!",
    back: "वापस",
    skip: "छोड़ें",
    goToPreviousStep: "पिछले चरण पर जाएं",
    goToNextStep: "अगले चरण पर जाएं",
    yourProgress: "आपकी प्रगति",
    typeMessage: "यहां संदेश टाइप करें",
    readMore: "...अधिक पढ़ें",
    readLess: "कम पढ़ें",
    fiIsSetWithValue: "FI को मान के साथ सेट किया गया है",
    fiValueAreClear: "FI मूल्य के साथ स्पष्ट किया गया है",
    progressBarIsSetTo: "प्रगति पट्टी इस पर सेट है",
    apIsBeenCalled: "एपीआई कॉल किया गया है",
    Status: "स्थिति",
    tagHasBeenAdd: "टैग जोड़ दिया गया है",
    tagHasBeenRemove: "टैग हटा दिया गया है",
    flowStarted: "फ्लो स्टार्टेड",
    handoffWillBeAssignedTo: "हैंडऑफ़ को असाइन किया जाएगा",
    randomizeRunAndOptions: "रन और ऑप्शंस को रैंडमाइज़ करें",
    emotionIsBeenRecognize: "इमोशन को पहचान लिया गया है",
    isChacked: "चेक किया गया है",
    triggerMailRequestGeneratedFor: "ट्रिगर मेल अनुरोध के लिए जनरेट किया गया है",
    delayOf: "देरी",
    status: "स्थिति",
    dynamicBlockRun: "डायनामिकब्लॉक रन है और स्थिति है",
    operation: "ऑपरेशन की स्थिति",
    fail: "विफल",
    success: "सफलता",
    clickHere: "यहाँ क्लिक करें",
    similarIntentName: "समान आशय का नाम",
    ofNLP: "एनएलपी . का",
    withScore: "स्कोर के साथ"
  },
  id: {
    email: "Harap masukkan email yang valid!",
    number: "Harap masukkan nomor yang valid!",
    contactNumber: "Harap masukkan nomor ponsel yang valid!",
    attachment: "hanya audio, video, gambar dan dokumen yang diperbolehkan!",
    bookingTypeNotAvailable: "Jenis pemesanan Tidak Tersediaं",
    pleaseSelectOneItem: "बSilakan pilih setidaknya satu item!",
    back: "Kembali",
    skip: "Melewatkan",
    goToPreviousStep: "pergi Ke Langkah Sebelumnya",
    goToNextStep: "pergi Ke Langkah Berikutnya",
    yourProgress: "Kemajuan Anda",
    typeMessage: "Ketik pesan di sini",
    readMore: "...Baca selengkapnya",
    readLess: "bacaKurang",
    fiIsSetWithValue: "FI disetel dengan nilai",
    fiValueAreClear: "Nilai FI jelas",
    progressBarIsSetTo: "Bilah kemajuan disetel ke",
    apiBeenCalled: "API telah dipanggil",
    status: "Status",
    tagHasBeenAdd: "Tag telah ditambahkan",
    tagHasBeenRemove: "Tag telah dihapus",
    flowStarted: "Alur Dimulai",
    handoffWillBeAssignedTo: "Handoff akan ditetapkan ke",
    randomizeRunAndOptions: "mengacak Jalankan dan Opsi",
    emotionIsBeenRecognize: "emosi telah dikenali",
    isChacked: "dicentang",
    triggerMailRequestGeneratedFor: "Permintaan Surat Pemicu dibuat untuk",
    delayOf: "Penundaan",
    apIsBeenCalled: "API dipanggil",
    dynamicBlockRun: "dynamicBlock dijalankan dan statusnya adalah",
    operation: "status operasi",
    fail: "Gagal",
    success: "success",
    clickHere: "kesuksesan",
    similarIntentName: "Nama Niat Serupa",
    ofNLP: "dari NLP",
    withScore: "dengan skor"
  },
  it: {
    email: "Perfavore Inserisci un'email valida!",
    number: "Inserisci un numero valido!",
    contactNumber: "Inserisci un numero di cellulare valido!",
    attachment: "sono consentiti solo audio, video, immagini e documenti!",
    bookingTypeNotAvailable: "Pemesanan tidak tersedia\n",
    pleaseSelectOneItem: "Seleziona almeno un articolo!",
    back: "Indietro",
    skip: "Salta",
    goToPreviousStep: "Vai al passaggio precedente",
    goToNextStep: "Vai al passaggio successivo",
    yourProgress: "I tuoi progressi",
    typeMessage: "Scrivi un messaggio qui",
    readMore: "...leggi di più",
    readLess: "Leggi meno",
    fiIsSetWithValue: "FI è impostato con valore",
    fiValueAreClear: "I valori FI sono chiari",
    progressBarIsSetTo: "La barra di avanzamento è impostata su",
    apIsBeenCalled: "API è stata chiamata",
    stato: "Stato",
    tagHasBeenAdd: "Il tag è stato aggiunto",
    tagHasBeenRemove: "Il tag è stato rimosso",
    flowStarted: "Flusso avviato",
    handoffWillBeAssignedTo: "Handoff sarà assegnato a",
    randomizeRunAndOptions: "Randomizza corsa e opzioni",
    emotionIsBeenRecognize: "l'emozione è stata riconosciuta",
    isChacked: "è selezionata",
    triggerMailRequestGeneratedFor: "Richiesta di attivazione della posta generata per",
    delayOf: "Ritardo di",
    status: "Status",
    dynamicBlockRun: "dynamicBlock è Run e lo stato è",
    operation: "operation Status",
    fail: "stato operativo",
    success: "successo",
    clickHere: "Clicca qui",
    similarIntentName: "Nome intento simile",
    ofNLP: "di PNL",
    withScore: "con punteggio"
  },
  ja: {
    email: "有効なメールアドレスを入力してください。",
    number: "有効な番号を入力してください！",
    contactNumber: "有効な携帯電話番号を入力してください！",
    attachment: "オーディオ、ビデオ、画像、ドキュメントのみが許可されています！",
    bookingTypeNotAvailable: "予約不可",
    pleaseSelectOneItem: "アイテムを1つ以上選択してください！",
    back: "バック",
    skip: "スキップ",
    goToPreviousStep: "前のステップに進みます",
    goToNextStep: "次のステップに進みます",
    yourProgress: "あなたの進歩",
    typeMessage: "ここにメッセージを入力してください",
    readMore: "...続きを読む",
    readLess: "続きを読む",
    fiIsSetWithValue: "FIは値で設定されます",
    fiValueAreClear: "FI値は明確です",
    progressBarIsSetTo: "プログレスバーがに設定されています",
    apIsBeenCalled: "APIが呼び出されました",
    status: "ステータス",
    tagHasBeenAdd: "タグが追加されました",
    tagHasBeenRemove: "タグが削除されました",
    flowStarted: "フロー開始",
    handoffWillBeAssignedTo: "ハンドオフが割り当てられます",
    randomizeRunAndOptions: "実行とオプションのランダム化",
    emotionIsBeenRecognize: "感情が認識されます",
    isChacked: "チェックされます",
    triggerMailRequestGeneratedFor: "トリガーメール要求が生成されました",
    delayOf: "遅延",
    dynamicBlockRun: "dynamicBlockは実行中、ステータスは ",
    operation: "運用状況",
    fail: "失敗",
    success: "成功",
    clickHere: "ここをクリック",
    similarIntentName: "類似のインテント名",
    ofNLP: "NLPの",
    withScore: "スコア付き"
  },
  ko: {
    email: "유효한 이메일을 입력하십시오!",
    number: "유효한 번호를 입력하십시오!",
    contactNumber: "유효한 휴대폰 번호를 입력하십시오!",
    attachment: "오디오, 비디오, 이미지 및 문서 만 허용됩니다!",
    bookingTypeNotAvailable: "예약 불가",
    pleaseSelectOneItem: "하나 이상의 항목을 선택하십시오!",
    back: "뒤",
    skip: "건너 뛰기",
    goToPreviousStep: "이전 단계로 이동",
    goToNextStep: "다음 단계로 이동",
    yourProgress: "진행 상황",
    typeMessage: "여기에 메시지를 입력하세요",
    readMore: "...더 읽기",
    readLess: "읽지 않음",
    fiIsSetWithValue: "FI가 값으로 설정됨",
    fiValueAreClear: "FI 값이 명확합니다",
    progressBarIsSetTo: "진행률 표시줄이 다음으로 설정됨",
    apIsBeenCalled: "API가 호출되었습니다",
    status: "상태",
    tagHasBeenAdd: "태그가 추가되었습니다",
    tagHasBeenRemove: "태그가 제거되었습니다.",
    flowStarted: "흐름 시작됨",
    handoffWillBeAssignedTo: "핸드오프가 할당됩니다",
    randomizeRunAndOptions: "무작위 실행 및 옵션",
    emotionIsBeenRecognize: "감정이 인식되었습니다",
    isChacked: "확인됨",
    triggerMailRequestGeneratedFor: "트리거 메일 요청 생성",
    delayOf: "지연",
    dynamicBlockRun: "dynamicBlock은 실행이고 상태는",
    operation: "작업 상태",
    fail: "불합격",
    success: "성공",
    clickHere: "여기를 클릭하십시오",
    similarIntentName: "유사한 의도 이름",
    ofNLP: "NLP의",
    withScore: "점수로"
  },
  no: {
    email: "Vennligst skriv inn gyldig e-postadresse!",
    number: "Vennligst skriv inn gyldig nummer!",
    contactNumber: "Vennligst skriv inn gyldig mobilnummer!",
    attachment: "bare lyd, video, bilde og dokumenter er tillatt!",
    bookingTypeNotAvailable: "Bestilling ikke tilgjengelig",
    pleaseSelectOneItem: "Velg minst ett element!",
    back: "Tilbake",
    skip: "Hopp over",
    goToPreviousStep: "Gå til forrige trinn",
    goToNextStep: "Gå til neste trinn",
    yourProgress: "Din fremgang",
    typeMessage: "Skriv inn en melding her",
    readMore: "... Les mer",
    readLess: "lese mindre",
    fiIsSetWithValue: "FI er satt med verdi",
    fiValueAreClear: "FI-verdier er klare",
    progressBarIsSetTo: "Fremdriftslinjen er satt til",
    apIsBeenCalled: "API er blitt kalt",
    status: "Status",
    tagHasBeenAdd: "Tag har blitt lagt til",
    tagHasBeenRemove: "Tag har blitt fjernet",
    flowStarted: "Flow startet",
    handoffWillBeAssignedTo: "Overlevering vil bli tildelt",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "følelser er blitt gjenkjent",
    isChacked: "er sjekket",
    triggerMailRequestGeneratedFor: "Trigger Mail-forespørsel generert for",
    delayOf: "Forsinkelse av",
    dynamicBlockRun: "dynamicBlock er Kjør og status er",
    operation: "driftsstatus",
    fail: "Mislykket",
    success: "suksess",
    clickHere: "Klikk her",
    similarIntentName: "Lignende hensiktsnavn",
    ofNLP: "av NLP",
    withScore: "med poengsum"
  },
  pl: {
    email: "Proszę podać poprawny adres e-mail!",
    number: "Wpisz prawidłowy numer!",
    contactNumber: "Proszę podać poprawny numer telefonu komórkowego!",
    attachment: "dozwolone są tylko pliki audio, wideo, obrazy i dokumenty!",
    bookingTypeNotAvailable: "Rezerwacja niedostępna",
    pleaseSelectOneItem: "Wybierz co najmniej jeden przedmiot!",
    back: "Z powrotem",
    skip: "Pominąć",
    goToPreviousStep: "Przejdź do poprzedniego kroku",
    goToNextStep: "Przejdź do następnego kroku",
    yourProgress: "Twój postęp",
    typeMessage: "Wpisz wiadomość tutaj",
    readMore: "... Czytaj więcej",
    readLess: "mniej czytać",
    fiIsSetWithValue: "FI jest ustawione na wartość",
    fiValueAreClear: "Wartości FI są jasne",
    progressBarIsSetTo: "Pasek postępu jest ustawiony na",
    apIsBeenCalled: "Wywołano API",
    status: "Status",
    tagHasBeenAdd: "Tag został dodany",
    tagHasBeenRemove: "Tag został usunięty",
    flowStarted: "Flow Started",
    handoffWillBeAssignedTo: "Przekazanie zostanie przypisane do",
    randomizeRunAndOptions: "randomizuj przebieg i opcje",
    emotionIsBeenRecognize: "emocja została rozpoznana",
    isChacked: "jest zaznaczone",
    triggerMailRequestGeneratedFor: "Żądanie wyzwalania poczty wygenerowane dla",
    delayOf: "Opóźnienie",
    dynamicBlockRun: "dynamicBlock to Uruchom, a stan to",
    operation: "Stan operacji",
    fail: "Ponieść porażkę",
    success: "powodzenie",
    clickHere: "Kliknij tutaj",
    similarIntentName: "Podobna nazwa intencji",
    ofNLP: "NLP",
    withScore: "z punktacją"
  },
  "pt-BR": {
    email: "Por favor insira um email válido!",
    number: "Por favor insira um número válido!",
    contactNumber: "Por favor, insira um número de celular válido!",
    attachment: "apenas áudio, vídeo, imagem e documentos permitidos!",
    bookingTypeNotAvailable: "Reserva não disponível",
    pleaseSelectOneItem: "Selecione pelo menos um item!",
    back: "Voltar",
    skip: "Pular",
    goToPreviousStep: "Vá para a etapa anterior",
    goToNextStep: "Vá para a próxima etapa",
    yourProgress: "Seu progresso",
    typeMessage: "Digite uma mensagem aqui",
    readMore: "... consulte Mais informação",
    readLess: "Não lida",
    fiIsSetWithValue: "FI é definido com valor",
    fiValueAreClear: "Os valores FI são claros",
    progressBarIsSetTo: "Progress bar is set to",
    apIsBeenCalled: "API foi chamada",
    status: "Status",
    tagHasBeenAdd: "Tag foi adicionada",
    tagHasBeenRemove: "Tag foi removida",
    flowStarted: "Fluxo iniciado",
    handoffWillBeAssignedTo: "Handoff será atribuído a",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "a emoção foi reconhecida",
    isChacked: "está marcado",
    triggerMailRequestGeneratedFor: "Trigger Mail request gerado para",
    delayOf: "Atraso de",
    dynamicBlockRun: "dynamicBlock é Run e o status é",
    operation: "Status de operação",
    fail: "Falhou",
    success: "sucesso",
    clickHere: "Clique aqui",
    similarIntentName: "Nome de intenção semelhante",
    ofNLP: "de PNL",
    withScore: "com pontuação"
  },
  pt: {
    email: "Por favor insira um email válido!",
    number: "Por favor insira um número válido!",
    contactNumber: "Por favor, insira um número de celular válido!",
    attachment: "apenas áudio, vídeo, imagem e documentos permitidos!",
    bookingTypeNotAvailable: "Reserva não disponível",
    pleaseSelectOneItem: "Selecione pelo menos um item!",
    back: "Voltar",
    skip: "Pular",
    goToPreviousStep: "Vá para a etapa anterior",
    goToNextStep: "Vá para a próxima etapa",
    yourProgress: "Seu progresso",
    typeMessage: "Digite uma mensagem aqui",
    readMore: "... consulte Mais informação",
    readLess: "Não lida",
    fiIsSetWithValue: "FI é definido com valor",
    fiValueAreClear: "Os valores FI são claros",
    progressBarIsSetTo: "Progress bar is set to",
    apIsBeenCalled: "API foi chamada",
    status: "Status",
    tagHasBeenAdd: "Tag foi adicionada",
    tagHasBeenRemove: "Tag foi removida",
    flowStarted: "Fluxo iniciado",
    handoffWillBeAssignedTo: "Handoff será atribuído a",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "a emoção foi reconhecida",
    isChacked: "está marcado",
    triggerMailRequestGeneratedFor: "Trigger Mail request gerado para",
    delayOf: "Atraso de",
    dynamicBlockRun: "dynamicBlock é Run e o status é",
    operation: "Status de operação",
    fail: "Falhou",
    success: "sucesso",
    clickHere: "Clique aqui",
    similarIntentName: "Nome de intenção semelhante",
    ofNLP: "de PNL",
    withScore: "com pontuação"
  },
  es: {
    email: "¡Por favor introduzca un correo electrónico válido!",
    number: "¡Ingrese un número válido!",
    contactNumber: "Introduzca un número de móvil válido.",
    attachment: "solo se permiten audio, video, imagen y documentos!",
    bookingTypeNotAvailable: "Reserva no disponible",
    pleaseSelectOneItem: "¡Seleccione al menos un elemento!",
    back: "atrás",
    skip: "Saltar",
    goToPreviousStep: "Ir al paso anterior",
    goToNextStep: "Ir al paso siguiente",
    yourProgress: "Su progreso",
    typeMessage: "Escriba un mensaje aquí",
    readMore: "...Lee mas",
    readLess: "Leer menos",
    fiIsSetWithValue: "FI se establece con valor",
    fiValueAreClear: "El valor de FI es claro",
    progressBarIsSetTo: "La barra de progreso está establecida en",
    apIsBeenCalled: "Se ha llamado a la API",
    status: "Estado",
    tagHasBeenAdd: "Se ha añadido la etiqueta",
    tagHasBeenRemove: "La etiqueta se ha eliminado",
    flowStarted: "Flujo iniciado",
    handoffWillBeAssignedTo: "La transferencia se asignará a",
    randomizeRunAndOptions: "Ejecutar aleatoriamente y Opciones",
    emotionIsBeenRecognize: "la emoción se ha reconocido",
    isChacked: "está marcado",
    triggerMailRequestGeneratedFor: "Solicitud de correo de activación generada para",
    delayOf: "Retraso de",
    dynamicBlockRun: "dynamicBlock es Run y el estado es",
    operation: "estado de operación",
    fail: "Fallar",
    success: "éxito",
    clickHere: "Haga clic aquí",
    similarIntentName: "Nombre de intención similar",
    ofNLP: "de PNL",
    withScore: "con puntaje"
  },
  "es-419": {
    email: "¡Por favor introduzca un correo electrónico válido!",
    number: "¡Ingrese un número válido!",
    contactNumber: "Introduzca un número de móvil válido.",
    attachment: "solo se permiten audio, video, imagen y documentos!",
    bookingTypeNotAvailable: "Reserva no disponible",
    pleaseSelectOneItem: "¡Seleccione al menos un elemento!",
    back: "atrás",
    skip: "Saltar",
    goToPreviousStep: "Ir al paso anterior",
    goToNextStep: "Ir al paso siguiente",
    yourProgress: "Su progreso",
    typeMessage: "Escriba un mensaje aquí",
    readMore: "...Lee mas",
    readLess: "Leer menos",
    fiIsSetWithValue: "FI se establece con valor",
    fiValueAreClear: "El valor de FI es claro",
    progressBarIsSetTo: "La barra de progreso está establecida en",
    apIsBeenCalled: "Se ha llamado a la API",
    status: "Estado",
    tagHasBeenAdd: "Se ha añadido la etiqueta",
    tagHasBeenRemove: "La etiqueta se ha eliminado",
    flowStarted: "Flujo iniciado",
    handoffWillBeAssignedTo: "La transferencia se asignará a",
    randomizeRunAndOptions: "Ejecutar aleatoriamente y Opciones",
    emotionIsBeenRecognize: "la emoción se ha reconocido",
    isChacked: "está marcado",
    triggerMailRequestGeneratedFor: "Solicitud de correo de activación generada para",
    delayOf: "Retraso de",
    dynamicBlockRun: "dynamicBlock es Run y el estado es",
    operation: "estado de operación",
    fail: "Fallar",
    success: "éxito",
    clickHere: "Haga clic aquí",
    similarIntentName: "Nombre de intención similar",
    ofNLP: "de PNL",
    withScore: "con puntaje"
  },
  "es-ES": {
    email: "¡Por favor introduzca un correo electrónico válido!",
    number: "¡Ingrese un número válido!",
    contactNumber: "Introduzca un número de móvil válido.",
    attachment: "solo se permiten audio, video, imagen y documentos!",
    bookingTypeNotAvailable: "Reserva no disponible",
    pleaseSelectOneItem: "¡Seleccione al menos un elemento!",
    back: "atrás",
    skip: "Saltar",
    goToPreviousStep: "Ir al paso anterior",
    goToNextStep: "Ir al paso siguiente",
    yourProgress: "Su progreso",
    typeMessage: "Escriba un mensaje aquí",
    readMore: "...Lee mas",
    readLess: "Leer menos",
    fiIsSetWithValue: "FI se establece con valor",
    fiValueAreClear: "El valor de FI es claro",
    progressBarIsSetTo: "La barra de progreso está establecida en",
    apIsBeenCalled: "Se ha llamado a la API",
    status: "Estado",
    tagHasBeenAdd: "Se ha añadido la etiqueta",
    tagHasBeenRemove: "La etiqueta se ha eliminado",
    flowStarted: "Flujo iniciado",
    handoffWillBeAssignedTo: "La transferencia se asignará a",
    randomizeRunAndOptions: "Ejecutar aleatoriamente y Opciones",
    emotionIsBeenRecognize: "la emoción se ha reconocido",
    isChacked: "está marcado",
    triggerMailRequestGeneratedFor: "Solicitud de correo de activación generada para",
    delayOf: "Retraso de",
    dynamicBlockRun: "dynamicBlock es Run y el estado es",
    operation: "estado de operación",
    fail: "Fallar",
    success: "éxito",
    clickHere: "Haga clic aquí",
    similarIntentName: "Nombre de intención similar",
    ofNLP: "de PNL",
    withScore: "con puntaje"
  },
  sv: {
    email: "Ange en giltig e-postadress!",
    number: "Var vänlig skriv in ett giltigt nummer!",
    contactNumber: "Ange ett giltigt mobilnummer!",
    attachment: "endast ljud, video, bild och dokument är tillåtna!",
    bookingTypeNotAvailable: "Bokning inte tillgänglig",
    pleaseSelectOneItem: "Välj minst ett objekt!",
    back: "Tillbaka",
    skip: "Hoppa",
    goToPreviousStep: "Gå till föregående steg",
    goToNextStep: "Gå till nästa steg",
    yourProgress: "Din framsteg",
    typeMessage: "Skriv ett meddelande här",
    readMore: "...Läs mer",
    readLess: "Läs mindre",
    fiIsSetWithValue: "FI är satt med värde",
    fiValueAreClear: "FI-värdet är tydligt",
    progressBarIsSetTo: "Förloppsindikatorn är inställd på",
    apIsBeenCalled: "API har anropats",
    status: "Status",
    tagHasBeenAdd: "Tagg har lagts till",
    tagHasBeenRemove: "Taggen har tagits bort",
    flowStarted: "Flöde startat",
    handoffWillBeAssignedTo: "Handoff kommer att tilldelas",
    randomizeRunAndOptions: "randomize Run and Options",
    emotionIsBeenRecognize: "känsla har blivit igenkänd",
    isChacked: "är kontrollerad",
    triggerMailRequestGeneratedFor: "Trigger Mail-begäran genererad för",
    delayOf: "Försening av",
    dynamicBlockRun: "dynamicBlock är Kör och status är",
    operation: "driftstatus",
    fail: "Misslyckas",
    success: "Framgång",
    clickHere: "Klicka här",
    similarIntentName: "Liknande avsiktsnamn",
    ofNLP: "av NLP",
    withScore: "med poäng"
  },
  th: {
    email: "กรุณาใส่อีเมล์ที่ถูกต้อง!",
    number: "กรุณากรอกหมายเลขที่ถูกต้อง!",
    contactNumber: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง!",
    attachment: "อนุญาตให้ใช้เฉพาะเสียงวิดีโอรูปภาพและเอกสารเท่านั้น!",
    bookingTypeNotAvailable: "ไม่เปิดให้จอง",
    pleaseSelectOneItem: "โปรดเลือกอย่างน้อยหนึ่งรายการ!",
    back: "กลับ",
    skip: "ข้าม",
    goToPreviousStep: "ไปที่ขั้นตอนก่อนหน้า",
    goToNextStep: "ไปที่ขั้นตอนถัดไป",
    yourProgress: "ความก้าวหน้าของคุณ",
    typeMessage: "พิมพ์ข้อความที่นี่",
    readMore: "...อ่านเพิ่มเติม",
    readLess: "อ่านน้อย",
    fiIsSetWithValue: "FI ถูกตั้งค่าด้วยค่า",
    fiValueAreClear: "ค่า FI ชัดเจน",
    ProgressBarIsSetTo: "แถบความคืบหน้าถูกตั้งค่าเป็น",
    apIsBeenCalled: "API ถูกเรียก",
    status: "สถานะ",
    tagHasBeenAdd: "เพิ่มแท็กแล้ว",
    tagHasBeenRemove: "แท็กถูกลบแล้ว",
    flowStarted: "เริ่มโฟลว์แล้ว",
    handoffWillBeAssignedTo: "แฮนด์ออฟจะถูกกำหนดให้",
    randomizeRunAndOptions: "สุ่มเรียกใช้และตัวเลือก",
    emotionIsBeenRecognize: "รับรู้อารมณ์แล้ว",
    isChacked: "ตรวจสอบแล้ว",
    triggerMailRequestGeneratedFor: "สร้างคำขอทริกเกอร์เมลสำหรับ",
    delayOf: "ล่าช้าของ",
    progressBarIsSetTo: "แถบความคืบหน้าถูกตั้งค่าเป็น",
    dynamicBlockRun: "dynamicBlock คือ Run และสถานะคือ",
    operation: "สถานะการดำเนินงาน",
    fail: "ล้มเหลว",
    success: "ความสำเร็จ",
    clickHere: "คลิกที่นี่",
    similarIntentName: "ชื่อเจตนาที่คล้ายกัน",
    ofNLP: "ของ NLP",
    withScore: "ด้วยคะแนน"
  },
  tr: {
    email: "Geçerli bir e!",
    number: "Lütfen geçerli bir numara girin!",
    contactNumber: "Lütfen geçerli bir telefon numarası giriniz!",
    attachment: "yalnızca ses, video, resim ve belgelere izin verilir!",
    bookingTypeNotAvailable: "Rezervasyon mevcut değil",
    pleaseSelectOneItem: "Lütfen en az bir öğe seçin!",
    back: "Geri",
    skip: "atla",
    goToPreviousStep: "Önceki Adıma Git",
    goToNextStep: "Sonraki Adıma Git",
    yourProgress: "Senin ilerlemen",
    typeMessage: "Buraya bir mesaj yazın",
    readMore: "...daha fazla oku",
    readLess: "az oku",
    fiIsSetWithValue: "FI değeri ile ayarlandı",
    fiValueAreClear: "FI değeri temiz",
    progressBarIsSetTo: "İlerleme çubuğu şuna ayarlı",
    apIsBeenCalled: "API çağrıldı",
    status: "Durum",
    tagHasBeenAdd: "Etiket eklendi",
    tagHasBeenRemove: "Etiket kaldırıldı",
    flowStarted: "Akış Başlatıldı",
    handoffWillBeAssignedTo: "Handoff atanacak",
    randomizeRunAndOptions: "Rundomize Run and Options",
    emotionIsBeenRecognize: "duygu tanındı",
    isChacked: "kontrol edildi",
    triggerMailRequestGeneratedFor: "Posta isteği için oluşturuldu tetikleyici",
    delayOf: "DelayOf",
    dynamicBlockRun: "dynamicBlock Çalıştır ve durum",
    operation: "çalışma Durumu",
    fail: "Hata",
    success: "başarı",
    clickHere: "Buraya tıklayın",
    similarIntentName: "Benzer Amaç Adı",
    ofNLP: "NLP'nin",
    withScore: "puanlı"
  },
  uk: {
    email: "Будь ласка, введіть дійсну адресу електронної пошти!",
    number: " Введіть дійсний номер!",
    contactNumber: "Введіть дійсний номер мобільного телефону!",
    attachment: " дозволяється лише аудіо, відео, зображення та документи!",
    bookingTypeNotAvailable: "Бронювання недоступне",
    pleaseSelectOneItem: "Будь ласка, виберіть хоча б один предмет!",
    back: "Назад",
    skip: "Пропустити",
    goToPreviousStep: "Перейти до попереднього кроку",
    goToNextStep: "Перейти до наступного кроку",
    yourProgress: "Ваш прогрес",
    typeMessage: "Введіть тут повідомлення",
    readMore: "... читатиБільш",
    readLess: "нечитаний",
    fiIsSetWithValue: "FI встановлено значенням",
    fiValueAreClear: "Значення FI зрозумілі",
    progressBarIsSetTo: "Індикатор перебігу встановлений на",
    apIsBeenCalled: "API викликано",
    status: "Статус",
    tagHasBeenAdd: "Тег додано",
    tagHasBeenRemove: "Тег видалено",
    flowStarted: "Потік розпочато",
    handoffWillBeAssignedTo: "Передача буде призначено",
    randomizeRunAndOptions: "рандомізувати запуск і параметри",
    emotionIsBeenRecognize: "емоцію розпізнають",
    isChacked: "перевірено",
    triggerMailRequestGeneratedFor: "Запит тригера створено для",
    delayOf: "Затримка",
    dynamicBlockRun: "dynamicBlock - це Виконати, а статус -",
    operation: "Стан роботи",
    fail: "Не вдалося",
    success: "успіх",
    clickHere: "Натисніть тут",
    similarIntentName: "Схожа назва наміру",
    ofNLP: "НЛП"
  }
};
